:where(.accordionRoot) {
	--content-padding: 8px 0px 24px 0px;
	--chevron-transiton: transform 0.2s ease;

	padding-bottom: var(--space-sm);
	display: flex;
	flex-direction: column;
	gap: 8px;

	& .chevron {
		transition: var(--chevron-transiton);
	}

	& .accordionItem {
		&[open] > .accordionTrigger > .chevron {
			transform: rotate(180deg);
		}
	}

	& .accordionTrigger {
		height: 31px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 4px;

		& svg {
			width: 24px;
			height: 24px;
		}
	}

	& .accordionContent {
		@extend %typo-body-1;

		padding: var(--content-padding);
	}
}
